/*
 * @Author: DQL
 * @Date: 2020-03-05 17:12:52
 * @LastEditors: cxb chenxiaobiao@hongmeng-info.com
 * @LastEditTime: 2023-05-09 17:43:31
 * @Description: file content
 */
import Vue from 'vue';
import axios from 'axios';
import App from './App.vue';
import config from './config'; // 系统配置文件
import router from './router.js'; // 主路由配置
import store from './store.js'; // 数据管理
import KestrelCoreUI from 'kestrel-core-ui'; // core-ui库
// import 'kestrel-core-ui/lib/kestrel-core-ui.css'; // core-ui库样式
// import 'kestrel-iconfont/lib/kestrel-iconfont.css'; // 图标库
import kestrelComponents from 'kestrel-components'; // 组件库
import HMAxiosInterceptor from './interceptor';
import * as echarts from 'echarts';
import 'animate.css';
import './style.scss';
import '@/banner.js';
// 自定义过滤器
import '@/filter';
import '@/features/components/component.js';
import EventBus from './event-bus';
import quasarConfig from '@/Quasar';
import aframe from 'aframe';
// eslint-disable-next-line no-unused-vars
import aframeAnimationMixer from '../public/3DModel/js/animation-mixer';
// import aframeAnimationMixer from 'aframe-extras.animation-mixer';
// eslint-disable-next-line no-unused-vars
import aframeAreaLightComponent from '../public/3DModel/js/aframe-area-light-component';
// eslint-disable-next-line no-unused-vars
import aframeTextGeometryComponent from '../public/3DModel/js/aframe-text-geometry-component';
require('aframe-troika-text');
require('../public/3DModel/js/aframe-orbit-controls');
require('../public/3DModel/js/aframe-geo-projection-component');

window.EventBus = EventBus;

// .finally is not a function —— 解决低版本浏览器axios不支持finally
// 参考：https://segmentfault.com/a/1190000015550213
require('promise.prototype.finally').shim();

Vue.config.productionTip = false;

// 应用ui库
Vue.use(KestrelCoreUI, {
  size: 'mini'
});

Vue.use(kestrelComponents);
Vue.prototype.$echarts = echarts;
Vue.use(...quasarConfig);
Vue.use(aframe);

// 调用ui库方法，配置请求拦截器
HMAxiosInterceptor(axios, router, {
  serverHeaderTokenName: config.serverHeaderTokenName,
  localTokenName: config.localTokenName
});

Vue.prototype.$config = config;

Vue.prototype.$axios = axios;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
